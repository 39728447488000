<template>
    <div>
        <h2 style="margin-top: 100px">Totalanalys</h2>
        <div class="login-form mt-5">
            <b-card style="max-width: 40rem;" class="mb-2 mx-auto">
                <b-card-text>
                    <p id="error" class="text-center maintenance-message text-danger mt-3">
                        Verktyget är för närvarande nedstängt för underhåll. Var god prova igen senare under dagen.
                    </p>
                    <div class="modal-footer clearfix">
                        <div class="float-right">
                            <b-button ref="inputButtonMaintenance" variant="primary" size="lg" @click="backToLogin">
                                Till Portalen
                            </b-button>
                        </div>
                    </div>
                </b-card-text>
            </b-card>
            <div v-if="visibleBanner" class="alert alert-dark text-center mb-0 banner browserWarning" role="alert">
                OBS: Verktyget är optimerat för Chrome browser

                <button type="button" class="btn btn-primary btn-sm ms-3 mb-lg-0 browserBannerBtn" @click="hideBrowserBanner()">
                    OK
                </button>
            </div>
            <p>Kontakta support@mms.se vid frågor om inlogg/verktyg</p>
        </div>
    </div>
</template>

<script>
import globalConfig from "@/mixins/globalConfig"
import router from "@/router/index"

export default {
    name: "Maintenance",
    mixins: [globalConfig],
    data() {
        return {
            visibleBanner: false,
        }
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
        this.$nextTick(() => {
            this.$refs.inputButtonMaintenance.focus()
            this.initializeBanner()
            if (!this.$store.getters.getMaintenanceStatus) {
                router.push("/")
            }
        })
    },
    methods: {
        initializeBanner() {
            if (localStorage.getItem("browserBanner") == null && !this.$browserDetect.isChrome) this.visibleBanner = true
        },
        hideBrowserBanner() {
            localStorage.setItem("browserBanner", true)
            this.visibleBanner = false
        },
        backToLogin() {
            this.$store.commit("setMaintenanceStatus", false)
            window.location.href = this.config_WebCaatAuthWebUrl + "/portal"
        },
    },
}
</script>

<style scoped>
.banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    border-radius: 0;
    background: #424851;
}

.browserWarning {
    color: red;
}
.maintenance-message {
    font-size: 1.5rem;
    font-weight: 700;
}
.browserBannerBtn {
    float: right;
    background: #97d058;
    border: none;
}
</style>
